import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "28",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#3BAF7E",
      d: "m.469 24.583 10.216-10.658a10.4 10.4 0 0 1-.196-2.05c0-1.885.457-3.741 1.327-5.395a11.05 11.05 0 0 1 3.653-4.066A10.47 10.47 0 0 1 20.549.68a10.4 10.4 0 0 1 5.252 1.027c.208.099.39.249.528.438a1.52 1.52 0 0 1 .226 1.339 1.5 1.5 0 0 1-.347.593l-6.105 6.35 2.526 2.635 6.09-6.366c.161-.164.357-.285.572-.354.215-.07.443-.083.664-.04.224.038.435.133.616.276.18.142.326.329.422.543a11.64 11.64 0 0 1-.696 10.802 10.96 10.96 0 0 1-3.927 3.804 10.4 10.4 0 0 1-5.2 1.358 9.2 9.2 0 0 1-1.965-.205L15.1 27.223c-.13.137-.285.246-.456.32a1.35 1.35 0 0 1-1.08 0 1.47 1.47 0 0 1-.764-.796 1.52 1.52 0 0 1 .308-1.602l4.651-4.9c.172-.177.385-.305.619-.372s.48-.07.715-.007a8 8 0 0 0 2.077.293 7.7 7.7 0 0 0 3.063-.61 7.9 7.9 0 0 0 2.604-1.79 8.3 8.3 0 0 0 1.744-2.695 8.6 8.6 0 0 0 .616-3.188q.004-.737-.112-1.463l-5.502 5.75a1.4 1.4 0 0 1-.456.32 1.35 1.35 0 0 1-1.08 0 1.4 1.4 0 0 1-.456-.32l-4.463-4.696a1.5 1.5 0 0 1-.395-1.017c0-.38.142-.745.395-1.017l5.459-5.736a8 8 0 0 0-1.403-.117c-2.108.003-4.129.88-5.618 2.435s-2.326 3.663-2.326 5.86c.005.733.1 1.46.281 2.167a1.52 1.52 0 0 1-.365 1.39L2.405 26.66a1.38 1.38 0 0 1-.996.43 1.38 1.38 0 0 1-.996-.43A1.5 1.5 0 0 1 0 25.62c0-.39.148-.762.413-1.037z"
    }, null, -1)
  ])))
}
export default { render: render }