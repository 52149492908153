export default function (groupKey?: string) {
  const ocStore = useOrderControlStore()
  const { $scrollTo } = useNuxtApp()
  let drillsToSearch: Drill[] = []

  if (groupKey) {
    const group = ocStore.getGroup(groupKey)
    drillsToSearch = group?.drills || []
  } else {
    ocStore.fitmentDrillKeys.forEach((drillKey) => {
      const drill = ocStore.getDrill(drillKey)
      if (drill) drillsToSearch.push(drill)
    })
    ocStore.optionDrillKeys.forEach((drillKey) => {
      const drill = ocStore.getDrill(drillKey)
      if (drill) drillsToSearch.push(drill)
    })
  }

  const targetDrill = drillsToSearch.find((drill) => drill.selectedValue === '-1')
  if (!targetDrill) return

  const targetElement = document.getElementById(targetDrill.id)
  if (!targetElement) return

  $scrollTo(`#${targetDrill.id}`, undefined, {
    offset: -40,
    // Primary add to cart button scrolls the product sticky panel
    ...(!groupKey && { container: '#product-sticky-panel' }),
  })
  flashElement(targetElement)
}

function flashElement(element: Element) {
  let i = 0

  const flash = setInterval(() => {
    element.classList.remove('alert')
    const alert = setTimeout(() => {
      element.classList.add('alert')
    }, 150)

    if (i == 3) {
      clearInterval(flash)
      clearTimeout(alert)
      element.classList.remove('alert')
    }
    i++
  }, 400)
}
