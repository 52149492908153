import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 41 41"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "data-name": "Layer 2" }, [
      _createElementVNode("g", { "data-name": "Capa 1" }, [
        _createElementVNode("path", {
          d: "M40.5 8V2.4A1.9 1.9 0 0 0 38.6.5H2.4A1.9 1.9 0 0 0 .5 2.4V8a1.8 1.8 0 0 0 1.3 1.8v21.4A1.9 1.9 0 0 0 .5 33v5.6a1.9 1.9 0 0 0 1.9 1.9h36.2a1.9 1.9 0 0 0 1.9-1.9V33a1.9 1.9 0 0 0-1.3-1.7V9.7A1.9 1.9 0 0 0 40.5 8m-1.3 30.6a.6.6 0 0 1-.6.7H2.4a.6.6 0 0 1-.6-.7V33a.6.6 0 0 1 .6-.6h36.2a.6.6 0 0 1 .6.6zM3 31.1V10h3.8v21zm27.5-5.9-4.1-4H33v4zm2.5 1.3v1.2l-1.2-1.2zm-7.8-6.6L21 15.8h12v4zm-5.4-5.3-4.6-4.7H33v4.7zM33 29.5V31h-5.4L8 11.5V10h5.4zm-23.8-15H8v-1.2zm1.3 1.3 4 4H8v-4zm5.3 5.3 4.1 4.1H8v-4zm5.3 5.4 4.7 4.6H8v-4.6zM34.3 31V10H38v21zm4.4-22.5H2.4a.6.6 0 0 1-.6-.6V2.4a.7.7 0 0 1 .6-.7h36.2a.6.6 0 0 1 .6.7V8a.6.6 0 0 1-.6.6z",
          "data-name": "packaging"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }