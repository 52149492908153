<template lang="pug">
div(
  class='customer-photos yotpo yotpo-pictures-widget [&_.yotpo-pictures-gallery-header-wrapper]:!hidden [&_.yotpo-lightbox-product-button]:!hidden'
  :data-gallery-id='galleryId'
  :data-product-id='yotpoId'
)
</template>

<script setup lang="ts">
const { $yotpo } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()

onMounted(() => {
  if (!isIntegrationEnabled('yotpo')) return

  // Gallery is still on V2 and must refresh the old way.
  $yotpo.addCallback(() => {
    try {
      const api = $yotpo.getApi()
      setTimeout(function () {
        api.refreshWidgets()
      }, 2000)
    } catch (err) {
      // do nothing, we hate you yotpo..
    }
  })
  $yotpo.load()
})

const { yotpoId, galleryId } = defineProps<{
  yotpoId: string
  galleryId: string
}>()
</script>
