import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "45 31 120 120"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M164.8 125.7a33.4 33.4 0 0 1-2.6 11.6c-1.1 2.8-4.5 5.8-10.2 8.9a32.5 32.5 0 0 1-28.7 1.7 318 318 0 0 1-8.8-3.2c-5.5-2-10.3-4.3-14.6-7q-10.8-6.6-22.2-18c-11.4-11.4-13.6-15-18-22.2a75.7 75.7 0 0 1-8.5-18.7 309 309 0 0 1-2.9-8.6 36 36 0 0 1-1-4.8A32 32 0 0 1 47 61q0-7.8 4.2-15.6c3.2-5.6 6.1-9 9-10.2a33.4 33.4 0 0 1 13.3-2.4c1 .4 2.5 2.5 4.4 6.4.7 1 1.5 2.6 2.6 4.5a416 416 0 0 0 2.9 5.3 178 178 0 0 0 5.8 9.6q.6 1.2.6 2.4c0 1-.8 2.5-2.4 4.2a40.7 40.7 0 0 1-5.1 4.6 50.5 50.5 0 0 0-5.2 4.4c-1.6 1.6-2.4 2.8-2.4 3.8 0 .5.1 1.2.4 2a18 18 0 0 0 1.9 3.7l1 1.5A87.8 87.8 0 0 0 92.4 105a87.7 87.7 0 0 0 21.3 15.5 30 30 0 0 0 3.7 2l1.9.3c1 0 2.3-.8 3.8-2.3a50.5 50.5 0 0 0 4.5-5.2c1.4-1.9 2.9-3.6 4.6-5.2s3-2.4 4.2-2.4c.7 0 1.5.2 2.3.6l3 1.8 2.1 1.4a178 178 0 0 0 9.8 5.6 211 211 0 0 1 4.5 2.5c4 2 6 3.4 6.4 4.4l.2 1.8z" }, null, -1)
  ])))
}
export default { render: render }