<template lang="pug">
Carousel(arrow-position='BOTTOM' :is-looped='isLooped')
  div(v-for='(item, index) in data' :key='item.image.key' class='max-w-[340px] mr-6 last:mr-0')
    img(
      v-if='item.image.key'
      :key='item.image.key'
      width='715'
      height='536'
      :src='getImageUrl(item.image.key, item.image.filename, 715, 536)'
      :alt='item.image.title || ""'
      class='cursor-pointer'
      loading='lazy'
      @click='loadLightbox(index)'
    )
    div(class='content pt-2')
      h4(v-if='item.title' class='font-bold my-0 pb-2 text-lg')
        InlineHtml(:text='item.title')
      div(v-if='item.content')
        InlineHtml(:text='item.content')
</template>
<script setup lang="ts">
const { getImageUrl } = useUrls()
const { showLightbox } = usePhotoswipeLightbox()
const { data, isLooped = false } = defineProps<{
  data: ImageBullet[]
  isLooped?: boolean
}>()

function loadLightbox(index: number) {
  const items: PhotoswipeItem[] = []
  data.forEach((bullet) => {
    if (!bullet.image.key) return
    items.push({
      src: getImageUrl(bullet.image.key, bullet.image.filename, 1024, 768) || '',
      width: 1024,
      height: 768,
      caption: bullet.title,
    })
  })
  showLightbox(items, index)
}
</script>
